<template>
  <div class="productId">
    <!-- mask  用户未登录使用  -->
    <div class="mask-not" v-show="notLogin">
      <div class="text-wrap">
        <div class="text">你还没有登录</div>
        <div class="user_to_login" @click="toLoginView">去登录</div>
      </div>
    </div>
    <!-- 背景 -->
    <div class="header-swiper">
      <!-- 蒙版 -->
      <div class="mast"></div>
      <!-- 轮播图 -->
      <el-carousel height="40vh">
        <el-carousel-item>
          <img class="swiper" :src="userinfo.bgimg" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- 头部信息 -->
      <div class="hearder-info">
        <div class="app-logo">
          <img :src="userinfo.userimg" alt="" />
        </div>
        <div class="app-data">
          <div class="name">
            {{ userinfo.username }}
            <div class="info_num">
              <div v-show="userinfo.gender == 2" class="read_num girl">
                <i class="iconfont icon-nv"></i>
                {{ userinfo.birthday | getAge }}
              </div>
              <div v-show="userinfo.gender == 1" class="read_num boy">
                <i class="iconfont icon-nan"></i>
                {{ userinfo.birthday | getAge }}
              </div>
              <div v-show="userinfo.gender == 0" class="read_num default">
                <i class="iconfont icon-bangzhu"></i>
                {{ userinfo.birthday | getAge }}
              </div>
            </div>
          </div>
          <div class="date">{{ userinfo.email }}</div>
          <!-- 用户标签 -->
          <div class="tags"></div>
        </div>
        <div class="app-but" @click="editUserInfo">
          <i class="iconfont icon-shezhi"></i>
          设置
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="app-main">
      <!-- 用户信息 -->
      <div class="main-data">
        <div class="text">
          <div class="label">个性签名:</div>
          <div class="data">{{ userinfo.text }}</div>
        </div>
        <!--  -->
      </div>
    </div>
    <!-- 功能区 -->
    <div class="top-text">收藏视频</div>
    <div class="viddeo-list">
      <div
        class="video"
        @click="toVideoDefault(item.id)"
        @mouseover="showMask(item.id)"
        @mouseout="hideMask"
        v-for="item in userinfo.video_list"
        :key="item.id"
      >
        <div class="img-wrap">
          <div :class="mouseId === item.id ? 'mask' : 'mask hide_mask'">
            <div class="play">
              <i class="iconfont icon-shipin-copy"></i>
            </div>
            <div class="foolter-data">
              <div class="read_num">
                <i class="iconfont icon-rentouliangrense"></i>
                {{ item.read_num }}
              </div>
              <div class="video-time">
                <i class="iconfont icon-shijian"></i>
                {{ item.time }}
              </div>
            </div>
          </div>
          <img class="start" :src="item.img" alt="" />
        </div>
        <div class="datas">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="about">
            <div class="cate">收藏</div>
            <div class="date">{{ item.date | getDateNum }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- logout -->
    <div class="logout" @click="logoutDialogVisible = true">退出登录</div>
    <!-- 退出登录 -->
    <el-dialog title="提示" :visible.sync="logoutDialogVisible" width="30%">
      <span>你确定退出登录吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="logout">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 对话框 -->
    <el-dialog
      title="修改信息"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="updateform" :rules="addFormRules" ref="addRef">
        <el-form-item label="头像" label-width="100px">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="pushUrl"
            :headers="uploadHearders"
            :on-success="handleSuccessUser"
          >
            <img
              v-if="updateform.userimg"
              :src="updateform.userimg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称" label-width="100px" prop="username">
          <el-input v-model="updateform.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" label-width="100px" prop="email">
          <el-input v-model="updateform.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="100px">
          <el-select v-model="updateform.gender" placeholder="请选择性别">
            <el-option label="未知" :value="0"></el-option>
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日" label-width="100px">
          <el-date-picker
            v-model="updateform.birthday"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="个性签名" label-width="100px" prop="text">
          <el-input v-model="updateform.text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="背景" label-width="100px">
          <el-upload
            class="bg-uplooad"
            :show-file-list="false"
            :action="pushUrl"
            :headers="uploadHearders"
            :on-success="handleSuccessBG"
          >
            <img
              v-if="updateform.bgimg"
              :src="updateform.bgimg"
              class="bg-show-img"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="修改密码" label-width="100px">
          <el-button type="primary" @click="editPasswordView"
            >修改密码</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditUser">确 定</el-button>
      </div>
    </el-dialog>

    <!--  -->
    <el-dialog title="修改密码" :visible.sync="editPasswordDailog">
      <el-form :model="updateform">
        <el-form-item label="确认密码" label-width="100px">
          <el-input v-model="updateform.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px">
          <el-input v-model="updateform.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPasswordDailog = false">取 消</el-button>
        <el-button type="primary" @click="editPasswordDailog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import comment from '../../../components/comment.vue'
import myStore from "../../../utils/localStore";
import formatDate from "../../../utils/formatDate";
import varites from "../../../utils/varites";

export default {
  name: "userinfo",
  //   components:{comment},
  data() {
    return {
      notLogin: true,
      id: "", // 目标用户id
      userinfo: {}, // 用户信息
      updateform: {}, // 修改表单
      logoutDialogVisible: false, // 退出登录
      dialogFormVisible: false, // 对话框
      editPasswordDailog: false, // 修改密码对话框
      form: {}, // 表单
      mouseId: null, // 激活的视频
      // 配置项
      pushUrl: varites["pushImgUrl"], // 图片上传地址
      uploadHearders: {
        /* 图片上传请求头 */
        Authorization: myStore.getStore("token"),
      },
      // 日期选择器配置
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 表单校验规则
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        text: [{ required: true, message: "请输入个性签名", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (myStore.getStore("userinfo")["id"]) {
      if (this.$store.state.userinfo.userInfo) {
        // 使用现有数据
        this.userinfo = this.$store.state.userinfo.userInfo;
      }else{
         this.getUserInfo();
      }
      this.notLogin = false;
    }
  },
  filters: {
    getAge(date) {
      let age = formatDate.format3(date);
      return age;
    },
    getDateNum(date) {
      let newDate = formatDate.format2(date);
      return newDate;
    },
  },
  methods: {
    // 获取用户数据
    async getUserInfo() {
      const { data: res } = await this.$http.get("userinfo/", {
        params: { id: myStore.getStore("userinfo")["id"] },
      });
      if (res.code !== "200") return;
      this.userinfo = res.data;
      // 保存信息
      this.$store.commit("userinfo/SAVE_USE5R_INFO", res.data);
    },

    // 修改用户数据
    async editUserInfo() {
      const { data: res } = await this.$http.get("userinfoupdate/");
      if (res.code == "408") {
        // token过期
        this.$notify({
          title: "登录过期",
          message: "请退出并重新登录",
        });
      }
      if (res.code !== "200") return;
      this.updateform = res.data;

      this.dialogFormVisible = true;
    },

    // 图片上传成功事件
    handleSuccessUser(response) {
      let picsinfo = { pic: response.src };
      this.updateform.userimg = picsinfo.pic;
    },
    handleSuccessBG(response) {
      let picsinfo = { pic: response.src };
      this.updateform.bgimg = picsinfo.pic;
    },

    // 修改密码
    editPasswordView() {
      this.editPasswordDailog = true;
    },

    // 确认修改
    submitEditUser() {
      this.$refs.addRef.validate(async (vali) => {
        if (!vali) return;
        // 表单提交
        const { data: res } = await this.$http.put(
          "userinfoupdate/",
          this.updateform
        );
        if (res.code !== "200") return;
        // 本地状态更新
        let newData = {
          id: this.updateform.id,
          username: this.updateform.username,
          userimg: this.updateform.userimg,
          bgimg: this.updateform.bgimg,
          token: myStore.getStore("userinfo")["token"],
        };
        if (res.data.token) {
          // 重新写入token
          myStore.setStore("token", res.data.token);
        }
        myStore.setStore("userinfo", newData);
        this.$bus.$emit("islogin");
        // 页面更新
        this.userinfo = { ...this.userinfo, ...this.updateform };

        this.dialogFormVisible = false;
      });
    },

    // 退出登录
    logout() {
      // 删除消息
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      this.$store.commit('userinfo/REMOVE_USER_INFO')
      //
      this.$router.replace("/login");
      //
      this.logoutDialogVisible = false;
    },

    // 没有登录，去登录
    toLoginView() {
      this.$router.push("/login");
    },

    // 视频模态框显示
    showMask(id) {
      this.mouseId = id;
    },
    // 隐藏模态框
    hideMask() {
      this.mouseId = null;
    },
    // 点击视频，进入视频详情d
    toVideoDefault(id) {
      console.log(id);
      this.$router.replace({
        path: "/home/video_list/videoDefault",
        query: { id },
      });
      // 刷新
      this.sendOperation(); // 记录数据
      this.$router.go(0);
    },
  },
};
</script>

<style lang='less' scoped>
// 轮播图
.swiper {
  width: 100%;
  height: 100%;
}

.productId {
  width: 100%;
  position: relative;

  .mask-not {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background: rgba(255, 255, 255, 0.6);

    .text-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 130px;
      height: 64px;

      .text {
        font-size: 20px;
        font-weight: 800;
        text-align: center;
      }

      .user_to_login {
        margin-top: 10px;
        margin-left: 25px;
        width: 80px;
        height: 30px;
        background: #3d59ef;
        color: #fff;
        text-align: center;
        line-height: 30px;
        border-radius: 10px;
        box-shadow: 1px 1px 1px rgb(100, 100, 100);
        cursor: pointer;
      }
    }
  }

  .header-swiper {
    position: relative;
    width: 100%;

    .mast {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 100;
    }

    // 头部信息
    .hearder-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 120px;
      z-index: 101;

      .app-logo {
        float: left;
        padding: 10px 20px;
        padding-left: 30px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }

      .app-data {
        float: left;
        padding-top: 28px;

        .name {
          font-size: 24px;
          font-weight: 700;
          color: rgb(255, 255, 255);
          position: relative;

          .info_num {
            position: absolute;
            top: 0;
            left: 110px;
            width: 300px;
            height: 30px;

            .read_num {
              float: left;
              width: 70px;
              height: 30px;
              font-size: 18px;
              color: #fff;
              text-align: center;
              line-height: 30px;
              border-radius: 13px;
              margin-right: 20px;
            }
            // 男/女
            .girl {
              background: #f88094;
            }
            .boy {
              background: #1296db;
            }
            .default {
              background: rgb(133, 133, 133);
            }
          }
        }
        .date {
          margin-top: 4px;
          color: rgb(255, 255, 255);
        }

        .tags {
          height: 24px;
          width: 320px;

          div {
            display: inline;
            margin: 0;
            height: 100%;
            padding: 1px 8px;
            padding-bottom: 3px;
            font-size: 15px;
            color: #fff;
            background: rgb(236, 14, 25);
            border-radius: 15px;
            margin: 0 3px;
            box-shadow: 2px 2px 1px rgb(155, 155, 155);
          }
        }
      }

      .app-but {
        float: right;
        margin-right: 40px;
        margin-top: 40px;
        width: 100px;
        height: 40px;
        background: #3d5aeb;
        text-align: center;
        line-height: 40px;
        color: #fff;
        border-radius: 15px;
        box-shadow: 1px 1px 1px rgb(29, 25, 95);

        i {
          font-size: 20px !important;
        }
      }
    }
  }

  .app-main {
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 30px;

    .main-data {
      margin-bottom: 20px;

      .text {
        width: 100%;
        .label {
          float: left;
          width: 12%;
        }
        .data {
          float: left;
          width: 88%;
          color: rgb(114, 114, 114);
        }
      }
    }
  }

  // 功能区
  .top-text {
    padding-left: 15px;
    margin-top: 15px;
    font-size: 18px;
  }

  // 退出登录
  .logout {
    margin-top: 280px;
    width: 60%;
    margin-left: 20%;
    height: 40px;
    background: #fff;
    color: red;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
  }
}

// 用户头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}

// 背景
.bg-uplooad .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.bg-uplooad .el-upload:hover {
  border-color: #409eff;
}
.bg-uplooad-icon {
  font-size: 28px;
  color: #8c939d;
  width: 110px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.bg-show-img {
  width: 118px;
  height: 78px;
  display: block;
}

// 视频列表
.viddeo-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .video {
    width: 200px;
    height: 150px;
    margin: 20px 15px;

    .img-wrap {
      height: 115px;
      width: 100%;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      // 模态框
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(1, 1, 1, 0.4);

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;

          i {
            font-size: 28px !important;
          }
        }
        .foolter-data {
          position: absolute;
          bottom: 0;
          height: 24px;
          line-height: 24px;
          width: 100%;
          padding: 0 5px;
          display: flex;
          justify-content: space-between;
          color: #fff;
          font-size: 10px;
          .read_num {
            i {
              font-size: 12px !important;
            }
          }

          .video-time {
            i {
              font-size: 12px !important;
            }
          }
        }
      }
      .start {
        background: #d4d4d4;
      }
    }

    .datas {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 13px;
        height: 35px;
        width: 100%;
        padding-right: 10px;
      }
      .about {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: rgb(109, 109, 109);
        .cate {
        }
        .date {
        }
      }
    }
  }
}

// 隐藏模态框
.hide_mask {
  display: none;
}
</style>